import axios from 'axios';
import * as url from '../constants';

export const Reporte_Lista_Etapa = (body:string, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get( url.baseURLSet.default + url.REPORTE_LISTA_ETAPA + body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
};

export const Reporte_Lista_Proceso = (Body:object, header:any) => {
    header.params = Body;

    return new Promise((resolve:any, reject:any) => {
        axios.get( url.baseURLSet.default + url.REPORTE_LISTA_PROCESO, header )
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
};

export const Reporte_Descargar_Excel_Proceso = (Body:object, header:any) => {
    header.params = Body;
    return new Promise((resolve:any, reject:any) => {
        axios.get( url.baseURLSet.default + url.REPORTE_DESCARGA_EXCEL_PROCESO, header )
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Reporte_Detalle_Proceso = (Id:number, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get( url.baseURLSet.default + url.REPORTE_DETALLE_PROCESO + Id, header )
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}