import ActionType from './../../constants';

const initialState = {
    seleccion:[]
}

const RCadenaValor_Lote = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.CADENA_VALOR_SELECCIONA_LOTE_ADD : {
            return { 
                ...state,
                seleccion: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RCadenaValor_Lote;