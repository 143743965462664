import ActionType from '../../constants';

const initialState = {
}

const RUserStakeholder = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.CUENTA_LISTA_USUARIO_STAKEHOLDER_ADD : {
            return { 
                ...state,
                list: action.payload
            }
        }
        case ActionType.CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_ADD: {
            return { 
                ...state,
                onlyOne: action.payload
            }
        }
        case ActionType.CUENTA_LISTA_STAKEHOLDER_ADD: {
            return { 
                ...state,
                listStkhldr: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RUserStakeholder;