import { takeLatest, call, put } from "redux-saga/effects";
import ActionType from "../constants";
import API_SERVICE from "../../services/api";
import * as Action from "../actions";


function* ECadena_Valor_Lista_Etapa(action:any): any{
    try{
        const payload = (yield call(API_SERVICE.Cadena_Valor_Lista_Etapa)).data.result;
        yield put(Action.ACadena_Valor_Lista_Etapa_Add(payload));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

/*-----------------------------------------------------------------*/
function* ECadena_Valor_Agrega(action:any): any{
    try {
        yield call(API_SERVICE.Cadena_Valor_Agrega, action.payload.data);

        const payload = yield call(API_SERVICE.Cadena_Valor_Lista, action.payload.grupo);
        const List = {List : payload.data.result, status : payload.status}
        TemplateInfo(payload.data.result.cadenasDeValor);
        yield put(Action.ACadena_Valor_Lista_Add(List));
        
        action.resolve();
    }
    catch(err){
        action.reject(err.response.data.responseException);

    }
}

function* ECadena_Valor_Actualiza(action:any): any{
    try {
        yield call(API_SERVICE.Cadena_Valor_Actualiza, action.payload.data );

        const payload = yield call(API_SERVICE.Cadena_Valor_Lista, action.payload.Lista );
        const List = {List : payload.data.result, status : payload.status};
        TemplateInfo(payload.data.result.cadenasDeValor);
        yield put(Action.ACadena_Valor_Lista_Add(List));

        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* ECadena_Valor_Elimina(action:any): any{
    try {
        yield call(API_SERVICE.Cadena_Valor_Elimina, action.payload.Id );

        const payload = yield call(API_SERVICE.Cadena_Valor_Lista, action.payload.Lista );
        const List = {List : payload.data.result, status : payload.status};
        TemplateInfo(payload.data.result.cadenasDeValor);
        yield put(Action.ACadena_Valor_Lista_Add(List));

        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* ECadena_Valor_Lista(action:any): any{
    try {
        const payload = yield call(API_SERVICE.Cadena_Valor_Lista, action.payload);
        const payloadEtapa = (yield call(API_SERVICE.Cadena_Valor_Lista_Etapa)).data.result;

        const List = {List : payload.data.result, status : payload.status};
        const ListEtapa:any = [];

        TemplateInfo(payload.data.result.cadenasDeValor);

        payloadEtapa.map((item:any) => {
            const temp = {
                label:item.nombre,
                value: item.id
            }
            ListEtapa.push(temp)
        })

        yield put(Action.ACadena_Valor_Lista_Etapa_Add(ListEtapa));
        yield put(Action.ACadena_Valor_Lista_Add(List));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}
/*-------------------------------*/
function* ECadena_Valor_Agrega_Lote(action:any): any{
    try{    
        yield call(API_SERVICE.Cadena_Valor_Agrega_Lote, action.payload.data);

        const payload = yield call(API_SERVICE.Cadena_Valor_Lista, action.payload.grupo);
        TemplateInfo(payload.data.result.cadenasDeValor);
        const List = {List : payload.data.result, status : payload.status}
        yield put(Action.ACadena_Valor_Lista_Add(List));

        action.resolve();
    }
    catch(err){
        action.reject(err.response.data.responseException);
    }
}

function* ECadena_Valor_Actualiza_Lote(action:any): any{
    try{    
        yield call(API_SERVICE.Cadena_Valor_Actualiza_Lote, action.payload);
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* ECadena_Valor_Elimina_Lote(action:any): any{
    try{    
        yield call(API_SERVICE.Cadena_Valor_Elimina_Lote, action.payload);
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* ECadena_Valor_Selecciona_Lote(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Cadena_Valor_Selecciona_Lote, action.payload)).data.result;
        yield put(Action.ACadena_Valor_Lista_Add(data));
        action.resolve();
    }
    catch(e){
        action.reject();
    }
}

export function* WCadena_Valor_Lista_Etapa():any{ return yield takeLatest(ActionType.CADENA_VALOR_LISTA_ETAPA_CALL, ECadena_Valor_Lista_Etapa)};

export function* WCadena_Valor_Agrega():any{ return yield takeLatest(ActionType.CADENA_VALOR_AGREGA_CALL, ECadena_Valor_Agrega)};
export function* WCadena_Valor_Actualiza():any{ return yield takeLatest(ActionType.CADENA_VALOR_ACTUALIZA_CALL, ECadena_Valor_Actualiza)};
export function* WCadena_Valor_Elimina():any{ return yield takeLatest(ActionType.CADENA_VALOR_ELIMINA_CALL, ECadena_Valor_Elimina)};
export function* WCadena_Valor_Lista():any{ return yield takeLatest(ActionType.CADENA_VALOR_LISTA_CALL, ECadena_Valor_Lista)};

export function* WCadena_Valor_Agrega_Lote():any{ return yield takeLatest(ActionType.CADENA_VALOR_AGREGA_LOTE_CALL, ECadena_Valor_Agrega_Lote)};
export function* WCadena_Valor_Actualiza_Lote():any{ return yield takeLatest(ActionType.CADENA_VALOR_ACTUALIZA_LOTE_CALL, ECadena_Valor_Actualiza_Lote)};
export function* WCadena_Valor_Elimina_Lote():any{ return yield takeLatest(ActionType.CADENA_VALOR_ELIMINA_LOTE_CALL, ECadena_Valor_Elimina_Lote)};
export function* WCadena_Valor_Selecciona_Lote():any{ return yield takeLatest(ActionType.CADENA_VALOR_SELECCIONA_LOTE_CALL, ECadena_Valor_Selecciona_Lote)};


const TemplateInfo = (data:any) => {

    data.map((item:any) => {

        let i = 0, temp:any={}, ListTemp:any=[];

        item.lotes.map((subItem:any, index:number) => {
           
            //colocamps el nombre y valor
            const Id = `idCadenaValorLote${i}`;
            const IdValue = subItem.id;
            const nombre = `codeLote${i}`;
            const value = subItem.codeLote;
            //agregamos a la lista temporal
            temp = {...temp, [nombre]:value, [Id]: IdValue} 
            //aumentamos, para que el nombre cambie
            i += 1;

            if(i === 4 || item.lotes.length === index+1){
                //le agregamos a que Lote esta.
                temp = {...temp, nombre: item.nombre}
                //solo se permiten 4 datos, una vez se pushea a la lista
                ListTemp.push(temp);
                //se limpian los datos y comienza de nuevo
                temp = {};
                i = 0;
            }
        })

        item.lotes = ListTemp;
    })

    return data;
}