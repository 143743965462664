import ActionType from '../constants';

const initialState = {
    etapa:[],
    metadata:[],
    status: 200
}

const RProceso = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.PROCESO_LISTA_PROCESO_ETAPA_ADD : {
            return { 
                ...state,
                listOnlyOne: action.payload
            }
        }
        case ActionType.PROCESO_LISTA_PROCESO_METADATA_ADD : {
            return {
                ...state,
                metadata: action.payload.data.result,
                status: action.payload.status
            }
        }
        case ActionType.PROCESO_SELECCIONA_ADD : {
            return {
                ...state,
                onlyOne: action.payload
            }
        }
        case ActionType.PROCESO_LISTA_REGISTROS_PARENT_ADD : {
            return {
                ...state,
                listRegister: action.payload
            }
        }
        case ActionType.PROCESO_LISTA_ETAPAS_PARENT_ADD : {
            return {
                ...state,
                listEtapas: action.payload
            }
        }
        case ActionType.PROCESO_REGISTROS_CONFECCION_ADD : {
            return {
                ...state,
                listConfeccion: action.payload
            }
        }
        case ActionType.PROCESO_GENERA_PRODUCTO_CODE_ADD : {
            return {
                ...state,
                genProducto: action.payload
            }
        }
        case ActionType.PROCESO_LISTA_PRODUCTO_CODE_ADD : {
            return {
                ...state,
                listproducto: action.payload
            }
        }
        case ActionType.PROCESO_DESCARGA_QR_ADD : {
            return {
                ...state,
                descQR: action.payload
            }
        }
        case ActionType.PROCESO_DESCARGA_PIN_ADD : {
            return {
                ...state,
                descPIN: action.payload
            }
        }
        case ActionType.PROCESO_LISTA_CODESKU_ADD : {
            return {
                ...state,
                codesku: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RProceso;