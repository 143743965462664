import ActionType from './../../constants';

const initialState = {
    List:[]
}

const RCadenaValor_Etapa = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.CADENA_VALOR_LISTA_ETAPA_ADD : {
            return { 
                ...state,
                List: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RCadenaValor_Etapa;