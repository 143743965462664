import axios from 'axios';
import * as url from './../constants';

export const Comercio_Agrega = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.COMERCIO_AGREGA, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Comercio_Actualiza = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.COMERCIO_ACTUALIZA, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Comercio_Elimina = (Id:number, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.delete(url.baseURLSet.default + url.COMERCIO_ELIMINA + Id, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Comercio_Selecciona = (Id:number, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.COMERCIO_SELECCIONA + Id, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Comercio_Lista = (Header:any, body:object) => {
    Header.params = body;
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.COMERCIO_LISTA, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Comercio_Lista_Cadena_Valor = (grupo:string, Header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.COMERCIO_LISTA_CADENA_VALOR + grupo, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Comercio_Lista_Lote = (Id:number, Header:any) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.COMERCIO_LISTA_LOTE + Id, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Comercio_Descarga_Excel = (body:any, Header:any) => {
    Header.params = body;
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.COMERCIO_DESCARGA_EXCEL, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}