import { takeLatest, call, put } from "redux-saga/effects";
import React from 'react';
import ActionType from "../constants";
import API_SERVICE from "../../services/api";
import * as Action from "../actions";

function* EProceso_Lista_Proceso_Metadata(action:any):any{
    try{
        const data = yield call(API_SERVICE.Proceso_Lista_Proceso_Metadata);
        //function de prueba para editar --> data.data.result[0].editar = false;

        data.data.result.etapas.map((item:any, index:number) => {
            const metadata:any = [];
            if(!item.headers) item.headers = [];

            item.headers.map((subitem:any, subIndex:number) => {
                let format:any = {};

                if(subitem.key === "Id" && item.editar ){
                    format.render = (text: any, record: any) => record.top ? <a onClick={() => action.Function({...record, asignaFuente: item.asignaFuente}, 1)}>{text}</a> : <a>{text}</a>
                }

                format = {
                    ...format,
                    title: subitem.title,
                    dataIndex: subitem.key,
                    key: subitem.key,
                    width: 150
                }
                metadata.push(format);
            })

            data.data.result.etapas[index].headers = metadata;
        })

        data.data.result.listas.map((item:any, index:number) => {
            const newData:Array<Object> = [];
            item.items.map((subItem:any) => {
                const newValue = {
                    label: subItem,
                    value: subItem
                }
                newData.push(newValue);
            })

            data.data.result.listas[index].items = newData;
        })

        yield put(Action.AProceso_Lista_Proceso_Metadata_Add(data));
        action.resolve();
    }
    catch{
        action.reject();
    }
}

function* EProceso_Lista_Proceso_Etapa(action:any):any{
    try{
        const etapa = (yield call(API_SERVICE.Proceso_Lista_Proceso_Etapa, action.payload)).data.result.items;
        const etapa_ = TemplateProceso(etapa);
        //const etapa_ = TemplateProceso2(etapa);
        yield put(Action.AProceso_Lista_Proceso_Etapa_Add(etapa_));
        action.resolve();
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

function* EProceso_Agrega(action:any):any{
    //idEtapaGrupoCadenaValor
    try{
        const res = yield call(API_SERVICE.Proceso_Agrega, action.payload);
        const etapa = (yield call(API_SERVICE.Proceso_Lista_Proceso_Etapa, {IdEtapa: action.payload.idEtapaGrupoCadenaValor})).data.result.items;
        const etapa_ = TemplateProceso(etapa);
        yield put(Action.AProceso_Lista_Proceso_Etapa_Add(etapa_));
        action.resolve(res.data.message);
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

function* EProceso_Actualiza(action:any):any{
    try{
        const res = yield call(API_SERVICE.Proceso_Actualiza, action.payload);
        const etapa = (yield call(API_SERVICE.Proceso_Lista_Proceso_Etapa, {IdEtapa: action.payload.idEtapaGrupoCadenaValor})).data.result.items;   
        const etapa_ = TemplateProceso(etapa);
        yield put(Action.AProceso_Lista_Proceso_Etapa_Add(etapa_));
        action.resolve(res.data.message);
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

function* EProceso_Selecciona(action:any):any{
    try{
        const payload = (yield call(API_SERVICE.Proceso_Selecciona, action.payload)).data.result;
        yield put(Action.AProceso_Selecciona_Add(payload));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EProceso_Lista_Etapas_Parent(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Proceso_Lista_Etapas_Parent, action.payload)).data.result;
        yield put(Action.AProceso_Lista_Etapas_Parent_Add(data));
        action.resolve();
    }
    catch{
        action.reject();
    }
}

function* EProceso_Lista_Registros_Parent(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Proceso_Lista_Registros_Parent, action.payload)).data.result;
        yield put(Action.AProceso_Lista_Registros_Parent_Add(data));
        action.resolve();
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* EProceso_Registros_Confeccion(action:any):any{
    try{
        if(action.payload.active) {
            const listCode = (yield call(API_SERVICE.Proceso_Lista_Codesku)).data.result, templateData:Array<object> = [];
            const arrayData = Object.entries(listCode);
            
            arrayData.map((item:any) => {
                templateData.push({
                    label: item[1],
                    value: item[0]
                })
            });

            yield put(Action.AProceso_Lista_Codesku_Add(templateData));
        }
        const data = (yield call(API_SERVICE.Proceso_Registros_Confeccion, action.payload.search)).data.result;
        yield put(Action.AProceso_Registros_Confeccion_Add(data));
        action.resolve();
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* EProceso_Genera_Producto_Code(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Proceso_Genera_Producto_Code, action.payload)).data.result;
        yield put(Action.AProceso_Genera_Producto_Code_Add(data));
        action.resolve();
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* EProceso_Lista_Producto_Code(action:any):any{
    try{
        
        const data = (yield call(API_SERVICE.Proceso_Lista_Producto_Code, action.payload)).data.result;
        const data_:any = [];

        data.map((item:any, index:any) => {
            const value = {
                label: item,
                value: item
            }
            data_.push(value);
        })
        yield put(Action.AProceso_Lista_Producto_Code_Add(data_));
        action.resolve();
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* EProceso_Descarga_Qr(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Proceso_Descargar_QR, action.payload)).data.result;
        yield put(Action.AProceso_Descarga_Qr_Add(data));
        action.resolve();
    }
    catch{
        action.reject();
    }
}

function* EProceso_Descarga_Pin(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Proceso_Descarga_Pin, action.payload)).data.result;
        yield put(Action.AProceso_Descarga_Pin_Add(data));
        action.resolve();
    }
    catch{
        action.reject();
    }
}

function* EProceso_Actualizar_Trazabilidad_Blockchain(action:any):any {
    try {
        const data = (yield call(API_SERVICE.Proceso_Actualizar_Trazabilidad_Blockchain)).data.result;
        const Proceso = (yield call(API_SERVICE.Reporte_Lista_Proceso, action.payload)).data.result;
        yield put(Action.AReporte_Lista_Proceos_Add({List_Temp: Proceso.items, total: Proceso.total}));

        action.resolve(data);
    } catch(err) {
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

export function* WProceso_Agrega():any{ return yield takeLatest(ActionType.PROCESO_AGREGA_CALL, EProceso_Agrega)};
export function* WProceso_Actualiza():any{ return yield takeLatest(ActionType.PROCESO_ACTUALIZA_CALL, EProceso_Actualiza)};
export function* WProceso_Selecciona():any{ return yield takeLatest(ActionType.PROCESO_SELECCIONA_CALL, EProceso_Selecciona)};
export function* WProceso_Lista_Proceso_Metadata():any{ return yield takeLatest(ActionType.PROCESO_LISTA_PROCESO_METADATA_CALL, EProceso_Lista_Proceso_Metadata)};
export function* WProceso_Lista_Proceso_Etapa():any{ return yield takeLatest(ActionType.PROCESO_LISTA_PROCESO_ETAPA_CALL, EProceso_Lista_Proceso_Etapa) };

export function* WProceso_Lista_Etapas_Parent():any{ return yield takeLatest(ActionType.PROCESO_LISTA_ETAPAS_PARENT_CALL, EProceso_Lista_Etapas_Parent)};
export function* WProceso_Lista_Registros_Parent():any{ return yield takeLatest(ActionType.PROCESO_LISTA_REGISTROS_PARENT_CALL, EProceso_Lista_Registros_Parent) };

export function* WProceso_Registros_Confeccion():any{ return yield takeLatest(ActionType.PROCESO_REGISTROS_CONFECCION_CALL, EProceso_Registros_Confeccion)};
export function* WProceso_Genera_Producto_Code():any{ return yield takeLatest(ActionType.PROCESO_GENERA_PRODUCTO_CODE_CALL, EProceso_Genera_Producto_Code)};
export function* WProceso_Lista_Producto_Code():any{ return yield takeLatest(ActionType.PROCESO_LISTA_PRODUCTO_CODE_CALL, EProceso_Lista_Producto_Code)};
export function* WProceso_Descarga_Qr():any{ return yield takeLatest(ActionType.PROCESO_DESCARGA_QR_CALL, EProceso_Descarga_Qr)};
export function* WProceso_Descarga_Pin():any{ return yield takeLatest(ActionType.PROCESO_DESCARGA_PIN_CALL, EProceso_Descarga_Pin)};

export function* WProceso_Actualizar_Trazabilidad_Blockchain():any{ return yield takeLatest(ActionType.PROCESO_ACTUALIZAR_TRAZABILIDAD_BLOCKCHAIN, EProceso_Actualizar_Trazabilidad_Blockchain)};

const TemplateProceso = (payload:any) => {
    const ArrayIdPadreInicio:any=[], data:any=[];

    payload.map((item_:any, index:number) => {
        const { item } = item_;

        //recoge todos los IdPadreInicio
        if(index === 0) ArrayIdPadreInicio.push(item);
        for(var i=0; i < ArrayIdPadreInicio.length; i++){
            if(ArrayIdPadreInicio[i].IdPadreInicio === item.IdPadreInicio) {
                return;
            };
        }

        ArrayIdPadreInicio.push(item);
    })

    ArrayIdPadreInicio.map((item:any) => {
        //Mapeo de los IdPadre Disponibles
        const ListforIdPadreInicio:any=[]

        for(var i = 0; i < payload.length; i++){
            //Busca en el arreglo el match de todos los datos
            if(payload[i].item.IdPadreInicio == item.IdPadreInicio) {
                ListforIdPadreInicio.push({...payload[i].item, readOnly: payload[i].readOnly})
            }
        }
        //ordena el arreglo por Id
        ListforIdPadreInicio.sort((a:any, b:any) => b.Id - a.Id);
        const length = ListforIdPadreInicio.length;
        let Father:any = ListforIdPadreInicio[0];
        Father.top = true;

        if(length > 1){
            //si tiene mas de un dato el IdPadre, los adicciona.
            ListforIdPadreInicio.splice(0, 1);
            Father.children=[...ListforIdPadreInicio];
        }
        //agrega los datos al arreglo final
        data.push(Father);

    })

    return data;
}

const TemplateProceso2 = (payload:any) => {
    const template = [] as any;
    payload.map((item:any, index:number) => {
        template.push(item.item);
    })
    return template;
}

const getExeception = (err:any) => {
    if(err.errors){
        const values = Object.entries(err.errors);
        const getMessages: Array<string> = [];

        values.map((item:any) => getMessages.push(item[1][0]))

        return getMessages;
    }
    else {
        return err;
    }
}