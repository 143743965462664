import { takeLatest, call, put } from "redux-saga/effects";
import API_SERVICE from "../../services/api";
import ActionType from "../constants";
import * as Action from "../actions";

function* EComercio_Agrega(action:any):any{
    try{    
        yield call(API_SERVICE.Comercio_Agrega, action.payload.data);

        const payload = yield call(API_SERVICE.Comercio_Lista, action.payload.lista);
        const List = {List : payload.data.result, status : payload.status}
        yield put(Action.AComercio_Lista_Add(List));

        action.resolve();
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

function* EComercio_Actualiza(action:any):any{
    try{    
        yield call(API_SERVICE.Comercio_Actualiza, action.payload.data);
        const payload = yield call(API_SERVICE.Comercio_Lista, action.payload.lista);
        const List = {List : payload.data.result, status : payload.status}
        yield put(Action.AComercio_Lista_Add(List));

        action.resolve();
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

function* EComercio_Elimina(action:any):any{
    try{    
        yield call(API_SERVICE.Comercio_Elimina, action.payload.data);
        const payload = yield call(API_SERVICE.Comercio_Lista, action.payload.lista);
        const List = {List : payload.data.result, status : payload.status}
        yield put(Action.AComercio_Lista_Add(List));
        action.resolve();
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

function* EComercio_Selecciona(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Comercio_Selecciona, action.payload)).data.result;
        yield put(Action.AComercio_Selecciona_Add(data));
        action.resolve();
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

function* EComercio_Lista(action:any):any{
    try{    
        const payload = yield call(API_SERVICE.Comercio_Lista, action.payload);
        const List = {List : payload.data.result, status : payload.status}
        yield put(Action.AComercio_Lista_Add(List));

        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EComercio_Lista_Cadena_Valor(action:any):any{
    try{    

        const payload = yield call(API_SERVICE.Comercio_Lista, { Grupo: action.payload } );
        //const payload = yield call(API_SERVICE.Comercio_Lista_Cadena_Valor, action.payload);

        const List_Comercio = {List : payload.data.result, status : payload.status}
;
        //const List = {List : payload.data.result, status : payload.status}

        yield put(Action.AComercio_Lista_Add(List_Comercio));
        //yield put(Action.AComercio_Lista_Cadena_Valor_Add(List));

        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EComercio_Lista_Lote(action:any):any{
    try{    
        const payload = yield call(API_SERVICE.Comercio_Lista_Lote, action.payload);
        const List = {List : payload.data.result, status : payload.status}
        yield put(Action.AComercio_Lista_Lote_Add(List));

        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EComercio_Descarga_Excel(action:any):any{
    try {
        const payload = (yield call(API_SERVICE.Comercio_Descarga_Excel, action.payload)).data.result;
        yield put(Action.AComercio_Descarga_Excel_Add(payload));

        action.resolve();
    }
    catch(err){
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = getExeception(errMessage);
        action.reject(newErr);
    }
}

export function* WComercio_Agrega():any{ return yield takeLatest( ActionType.COMERCIO_AGREGA_CALL, EComercio_Agrega ) };
export function* WComercio_Actualiza():any{ return yield takeLatest( ActionType.COMERCIO_ACTUALIZA_CALL, EComercio_Actualiza ) };
export function* WComercio_Elimina():any{ return yield takeLatest( ActionType.COMERCIO_ELIMINA_CALL, EComercio_Elimina ) };
export function* WComercio_Selecciona():any{ return yield takeLatest( ActionType.COMERCIO_SELECCIONA_CALL, EComercio_Selecciona ) };

export function* WComercio_Lista():any{ return yield takeLatest( ActionType.COMERCIO_LISTA_CALL, EComercio_Lista ) };
export function* WComercio_Lista_Cadena_Valor():any{ return yield takeLatest( ActionType.COMERCIO_LISTA_CADENA_VALOR_CALL, EComercio_Lista_Cadena_Valor ) };
export function* WComercio_Lista_Lote():any{ return yield takeLatest( ActionType.COMERCIO_LISTA_LOTE_CALL, EComercio_Lista_Lote ) };
export function* WComercio_Descarga_Excel():any{ return yield takeLatest( ActionType.COMERCIO_DESCARGA_EXCEL_CALL, EComercio_Descarga_Excel ) };

const getExeception = (err:any) => {
    if(err.errors){
        const values = Object.entries(err.errors);
        const getMessages: Array<string> = [];

        values.map((item:any) => getMessages.push(item[1][0]))

        return getMessages;
    }
    else {
        return err;
    }
}