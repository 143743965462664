import { combineReducers } from "redux";

import cuenta from './Cuenta/index';
import proceso from './Proceso';
import stakeholder from './stakeholder';
import cadenaValor from './cadenaValor';
import reporte from './Reporte';
import comercio from './Comercio';

const appReducer = combineReducers({
    cuenta,
    proceso,
    stakeholder,
    cadenaValor,
    reporte,
    comercio
});

export default appReducer;