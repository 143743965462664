import ActionType from './../../constants';

const initialState = {
    etapa: [],
    proceso: [],
    excel: [],
    cadenasDeValor: [],
    listE: []
}

const RReporte = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.REPORTE_LISTA_ETAPA_ADD : {
            return { 
                ...state,
                etapa: action.payload,  
            }
        }
        case ActionType.REPORTE_LISTA_PROCESO_ADD : {
            return {
                ...state,   
                proceso: action.payload
            }
        }
        case ActionType.REPORTE_DESCARGA_EXCEL_PROCESO_ADD : {
            return {
                ...state,
                excel: action.payload
            }
        }
        case ActionType.REPORTE_CADENA_VALOR_LISTA_ADD : {
            return {
                ...state,
                listC: action.payload
            }
        }
        case ActionType.REPORTE_PROCESO_METADATA_ETAPAS_ADD : {
            return {
                ...state,
                listE: action.payload
            }
        }
        case ActionType.REPORTE_DETALLE_PROCESO_ADD : {
            return {
                ...state,
                reporteDetalle: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RReporte;