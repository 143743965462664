import * as React from "react"

const renderLoader = () => <p>Loading</p>;

export default class ErrorBoundary extends React.Component<any, any> {
	
	constructor(props: any) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError(error: any) {
		return {hasError: true};
	}

	render() {
		if (this.state.hasError) {
			return <p>Loading failed! Please reload.</p>;
		}

		return this.props.children;
	}
};