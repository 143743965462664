import React from "react";
import { Helmet } from "react-helmet";
import "./App.scss";
import Main from "./containers/main";

const fullYear = (new Date()).getFullYear();
const copyright = `© Copyright ${fullYear} Magia Digital`;

const App: React.FC = () => {
	return (
		<div className="App">
			<Helmet titleTemplate="%s - SISSAI" /*defaultTitle="sin title..."*/>
				<meta name="description" content="SISSAI description" />
				<meta name="copyright" content={copyright} />
			</Helmet>		
			<Main />
		</div>
	);
}

export default App;
