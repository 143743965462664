import * as React from "react";
import { Redirect, Route } from "react-router";
import jwtDecode from 'jwt-decode';

export const JwtVerify = () => {

  const token = localStorage.getItem('token');

  if (token) {
    const payload = JSON.stringify(jwtDecode(token));
    const exp = JSON.parse(payload).exp;
    if (exp < Date.now() / 1000) {
      localStorage.clear();
      return false;
    }
    return true;
  }
  return false;
}

export const PrivateRoute = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={props =>
        JwtVerify() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
);