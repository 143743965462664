import axios from 'axios';
import * as url from './../constants';

export const Cadena_Valor_Agrega = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CADENA_VALOR_AGREGA, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Cadena_Valor_Actualiza = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CADENA_VALOR_ACTUALIZA, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Cadena_Valor_Elimina = (Id:number, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.delete(url.baseURLSet.default + url.CADENA_VALOR_ELIMINA + Id, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Cadena_Valor_Lista = (Header:any, grupo:string) => {

    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CADENA_VALOR_LISTA + grupo, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

//-------------------------------------------------------------------

export const Cadena_Valor_Agrega_Lote = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CADENA_VALOR_AGREGA_LOTE, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Cadena_Valor_Actualiza_Lote = (body:any, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CADENA_VALOR_ACTUALIZA_LOTE, body, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Cadena_Valor_Elimina_Lote = (Id:number, Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.delete(url.baseURLSet.default + url.CADENA_VALOR_ELIMINA_LOTE + Id, Header)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    })
}

export const Cadena_Valor_Seleciona_Lote = (Header:any, Id:number) => {
    Header.params = Id;
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CADENA_VALOR_SELECCIONA_LOTE, Header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

//-------------------------------------------------------------------

export const Cadena_Valor_Lista_Etapa = ( Header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CADENA_VALOR_LISTA_ETAPA, Header)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}