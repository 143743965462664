import ActionType from './../../constants';

const initialState = {
    Lista:{
        cadenasDeValor: []
    },
    SLista: 200
}

const RCadenaValor = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.CADENA_VALOR_LISTA_ADD : {
            return { 
                ...state,
                Lista: action.payload.List,
                SLista: action.payload.status 
            }
        }
        default : 
            return state;
    }
}

export default RCadenaValor;