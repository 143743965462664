import { rejects } from 'assert';
import axios from 'axios';
import * as url from './../constants';

export const Cuenta_Login = ( body:object ) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_LOGIN, body)
        .then((res:any) => 
            resolve(res)
        )
        .catch((err:any) => 
            reject(err)
        )
    }) 
}

export const Cuenta_Solicita_Recupera_Cuenta = (body:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_SOLICITA_RECUPERA_PASSWORD, body)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err:any) => {
            reject(err);
        })
    })
}

export const Cuenta_Valida_Hash_Recupera_Password = (body:string) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_VALIDA_HASH_RECUPERA_PASSWORD + body)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Valida_Hash_Activa_Cuenta = (hash:string) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_VALIDA_HASH_ACTIVA_CUENTA + hash)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Activa_Cuenta = (body:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_ACTIVA_CUENTA, body)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Recupera_Passsword = (body:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_RECUPERA_PASSWORD, body)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Cuenta_Cambia_Password = (body:object, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_CAMBIA_PASSWORD, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Tablero_Control = (header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_TABLERO_CONTROL, header)
        .then((res:any) => {
            resolve(res)
        })
        .catch((err:any) => {
            reject(err);
        })
    })
}

export const Cuenta_Menu_Lateral = (header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_MENU_LATERAL, header)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err:any) => {
            reject(err);
        })
    })
}

/* ------------------------------------------------------------------------------- */

export const Cuenta_Agrega_Usuario = (body: object, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_AGREGA_USUARIO, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Actualiza_Usuario = (body: object, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_ACTUALIZA_USUARIO, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Elimina_Usuario = (Id: number, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.delete(url.baseURLSet.default + url.CUENTA_ELIMINA_USUARIO + Id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Lista_Usuario = (header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_LISTA_USUARIO, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Selecciona_Usuario = (Id:number, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_SELECCIONA_USUARIO + Id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}



export const Cuenta_Agrega_Usuario_Stakeholder = (Id: number, body:object, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_AGREGA_USUARIO_STAKEHOLDER + Id, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Actualiza_Usuario_Stakeholder = (Id: number, body:object, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post(url.baseURLSet.default + url.CUENTA_ACTUALIZA_USUARIO_STAKEHOLDER + Id, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Elimina_Usuario_Stakeholder = (Id: number, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.delete(url.baseURLSet.default + url.CUENTA_ELIMINA_USUARIO_STAKEHOLDER + Id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Lista_Usuario_Stakeholder = (header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_LISTA_USUARIO_STAKEHOLDER, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Cuenta_Selecciona_Usuario_Stakeholder = (Id:number, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(url.baseURLSet.default + url.CUENTA_SELECCIONA_USUARIO_STAKEHOLDER + Id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}