import { combineReducers } from 'redux';
import myaccount from './MyAccount';
import usuario from './usuario';
import userStkdlr from './userStakeholder'

const RIndex = combineReducers({
    myaccount,
    usuario,
    userStkdlr
})

export default RIndex;