import { takeLatest, call, put } from "redux-saga/effects";
import { EyeOutlined } from '@ant-design/icons';
import React from 'react';
import API_SERVICE from "../../services/api";
import ActionType from "../constants";
import * as Action from "../actions";

function* EReporte_Lista_Etapa(action:any):any{
    try{
        const Etapa = (yield call(API_SERVICE.Reporte_Lista_Etapa, action.payload.etapa)).data.result;

        if(action.payload.proceso) {
            const Proceso = (yield call(API_SERVICE.Reporte_Lista_Proceso, action.payload.proceso)).data.result;

            const List_Temp:any = []
            Proceso.items.map((item:any, index:number) => {
                const temp = item.jsonData;
                item = {...item, ...temp}
                List_Temp.push(item);
            })

            yield put(Action.AReporte_Lista_Proceos_Add(List_Temp));
        }

        yield put(Action.AReporte_Lista_Etapa_Add(Etapa));

        action.resolve();
    }
    catch(err){
        action.reject(err);
    }
}

function* EReporte_Lista_Proceso(action:any):any{
    try{
        const Proceso = (yield call(API_SERVICE.Reporte_Lista_Proceso, action.payload)).data.result;

        const List_Temp:any = []
        Proceso.items.map((item:any, index:number) => {
            const temp = item.jsonData;
            const fuente = item.fuente[0];
            let proceso = {};

            if(fuente !== undefined) proceso = {proceso: fuente.etapa};
            item = {...item, ...temp, ...proceso}
            console.log(item);
            List_Temp.push(item);
        })

        yield put(Action.AReporte_Lista_Proceos_Add({List_Temp, total: Proceso.total}));

        action.resolve();
    }
    catch(err){
        action.reject(err);
    }
}

function* EReporte_Descargar_Excel_Proceso(action:any):any{
    try{
        const Excel = (yield call(API_SERVICE.Reporte_Descarga_Excel_Proceso, action.payload)).data.result;
        yield put(Action.AReporte_Descarga_Excel_Proceso_Add(Excel));

        action.resolve();
    }
    catch(err){
        action.reject(err);
    }
}

function* EReporte_Cadena_Valor_List(action:any):any{
    try{
        const payload = yield call(API_SERVICE.Cadena_Valor_Lista, action.payload);
        const List = {List : payload.data.result, status : payload.status};

        yield put(Action.AReporte_Cadena_Valor_Lista_Add(List));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EReporte_Proceso_Metadata_Etapas(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Cadena_Valor_Lista_Etapa)).data.result;
        yield put(Action.AReporte_Proceso_Metadata_Etapas_Add(data))
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EReporte_Detalle_Proceso(action:any):any{
    try {
        
        const data = (yield call (API_SERVICE.Reporte_Detalle_Proceso, action.payload)).data.result;
        
        data.lista.map((item:any, index:any) => {
            const template: Array<object> = [], template2: Array<object> = [];

            
            item.items.map(
                (item_:any) =>  item_._item.urlTraza = item_.urlTraza
            );

            item.headers.splice(1,0,{
                key: 'urlTraza',
                title: 'Blockchain',
                dataIndex: 'urlTraza',
                width: 100
            });

            item.headers.map((item_:any) => {
                const newItem:any = {
                    key: item_.key,
                    title: item_.title,
                    dataIndex: item_.key,
                    width: 150
                }

                if(item_.key === 'urlTraza') 
                    newItem.render = (text: any) => <a className="blockchain" href={text} target="_blank"> <EyeOutlined/> </a>;
                
                if(item_.key === 'Id') 
                    newItem.width = 100;

                template.push(newItem);
            })

            data.lista[index].items = children(item.items);
            data.lista[index].headers = template;
        })

        yield put (Action.AReporte_Detalle_Proceso_Add(data));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

export function* WReporte_Proceso_Metadata_Etapas():any{ return yield takeLatest( ActionType.REPORTE_PROCESO_METADATA_ETAPAS_CALL, EReporte_Proceso_Metadata_Etapas ) }
export function* WReporte_Cadena_Valor_List():any{ return yield takeLatest( ActionType.REPORTE_CADENA_VALOR_LISTA_CALL, EReporte_Cadena_Valor_List ) }
export function* WReporte_Lista_Etapa():any{ return yield takeLatest( ActionType.REPORTE_LISTA_ETAPA_CALL, EReporte_Lista_Etapa ) };
export function* WReporte_Lista_Proceso():any{ return yield takeLatest( ActionType.REPORTE_LISTA_PROCESO_CALL, EReporte_Lista_Proceso ) };
export function* WReporte_Descargar_Excel_Proceso():any{ return yield takeLatest( ActionType.REPORTE_DESCARGA_EXCEL_PROCESO_CALL, EReporte_Descargar_Excel_Proceso ) };
export function* WReporte_Detalle_Proceso():any{ return yield takeLatest( ActionType.REPORTE_DETALLE_PROCESO_CALL, EReporte_Detalle_Proceso ) };

const children = (payload:any) => {
    const ArrayIdPadreInicio:any=[],data:any=[];

    payload.map((item_:any, index:number) => {
        const { _item } = item_;

        //recoge todos los IdPadreInicio
        if(index === 0) ArrayIdPadreInicio.push(_item);
        for(var i=0; i < ArrayIdPadreInicio.length; i++){
            if(ArrayIdPadreInicio[i].IdPadreInicio === _item.IdPadreInicio) {
                return;
            };
        }

        ArrayIdPadreInicio.push(_item);
    })

    ArrayIdPadreInicio.map((item:any) => {
        //Mapeo de los IdPadre Disponibles
        const ListforIdPadreInicio:any=[]

        for(var i = 0; i < payload.length; i++){
            //Busca en el arreglo el match de todos los datos
            if(payload[i]._item.IdPadreInicio == item.IdPadreInicio) {
                ListforIdPadreInicio.push(payload[i]._item)
            }
        }
        //ordena el arreglo por Id
        ListforIdPadreInicio.sort((a:any, b:any) => b.Id - a.Id);
        const length = ListforIdPadreInicio.length;
        let Father:any = ListforIdPadreInicio[0];
        Father.top = true;

        if(length > 1){
            //si tiene mas de un dato el IdPadre, los adicciona.
            ListforIdPadreInicio.splice(0, 1);
            Father.children=[...ListforIdPadreInicio];
        }
        //agrega los datos al arreglo final
        data.push(Father);

    })

    return data;
}