import ActionType from './../../constants';

const initialState = {
    onlyOne: [],
    Lista: []
}

const RStakeholderType = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_ADD: {
            return { 
                ...state,
                onlyOne: action.payload
            }
        }
        case ActionType.STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_ADD : {
            return {
                ...state,
                Lista: action.payload
            }
        }
        case ActionType.STAKEHOLDER_PROCESO_TIPO_STAKEHOLDER_ADD : {
            return {
                ...state,
                Proceso: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RStakeholderType;