import ActionType from '../../constants';

const initialState = {
}

const RUsuario = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.CUENTA_LISTA_USUARIO_ADD : {
            return { 
                ...state,
                list: action.payload
            }
        }
        case ActionType.CUENTA_SELECCIONA_USUARIO_ADD: {
            return { 
                ...state,
                onlyOne: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RUsuario;