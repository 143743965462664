import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./root";
import reducers from "./reducers";

const sagaMiddleware = createSagaMiddleware();
let middlewares: any = [];

if (process.env.NODE_ENV === `development`) {
	const { logger } = require(`redux-logger`);
	middlewares = applyMiddleware(sagaMiddleware, logger);
} else {
	middlewares = applyMiddleware(sagaMiddleware);
}

const store = createStore(
	reducers,
	compose(middlewares)
);

sagaMiddleware.run(rootSaga);

export default store;