import { all } from "redux-saga/effects";
import * as functionsCuenta from './effects/Cuenta';
import * as functionsProceso from './effects/Proceso';
import * as functionsStakeholder from './effects/Stakeholder';
import * as functionsCadenaValor from './effects/Cadena-valor';
import * as functionsReporte from './effects/Reporte';
import * as functionsComercio from './effects/Comercio';

export function* rootSaga() {
	yield all([

		functionsCuenta.WCuenta_Login(),
		functionsCuenta.WCuenta_Tablero_Control(),
		functionsCuenta.WCuenta_Cambia_Password(),
		functionsCuenta.WCuenta_Menu_Lateral(),

		functionsCuenta.WCuenta_agrega_Usuario(),
		functionsCuenta.WCuenta_Actualiza_Usuario(),
		functionsCuenta.WCuenta_Elimina_Usuario(),
		functionsCuenta.WCuenta_Lista_Usuario(),
		functionsCuenta.WCuenta_Selecciona_Usuario(),

		functionsCuenta.WCuenta_agrega_Usuario_Stakeholder(),
		functionsCuenta.WCuenta_Actualiza_Usuario_Stakeholder(),
		functionsCuenta.WCuenta_Elimina_Usuario_Stakeholder(),
		functionsCuenta.WCuenta_Lista_Usuario_Stakeholder(),
		functionsCuenta.WCuenta_Selecciona_Usuario_Stakeholder(),

		functionsProceso.WProceso_Agrega(),
		functionsProceso.WProceso_Actualiza(),
		functionsProceso.WProceso_Selecciona(),
		functionsProceso.WProceso_Lista_Proceso_Etapa(),
		functionsProceso.WProceso_Lista_Proceso_Metadata(),
		functionsProceso.WProceso_Lista_Etapas_Parent(),
		functionsProceso.WProceso_Lista_Registros_Parent(),
		functionsProceso.WProceso_Registros_Confeccion(),
		functionsProceso.WProceso_Genera_Producto_Code(),
		functionsProceso.WProceso_Lista_Producto_Code(),
		functionsProceso.WProceso_Descarga_Qr(),
		functionsProceso.WProceso_Descarga_Pin(),
		functionsProceso.WProceso_Actualizar_Trazabilidad_Blockchain(),

		functionsStakeholder.WStakeholder_Actualiza_Stakeholder(),
		functionsStakeholder.WStakeholder_Agrega_Stakeholder(),
		functionsStakeholder.WStakeholder_Elimina_Stakeholder(),
		functionsStakeholder.WStakeholder_Selection_Stakeholder(),
		functionsStakeholder.WStakeholder_Lista_Stakeholder(),

		functionsStakeholder.WStakeholder_Actualiza_Tipo_Stakeholder(),
		functionsStakeholder.WStakeholder_Agrega_Tipo_Stakeholder(),
		functionsStakeholder.WStakeholder_Elimina_Tipo_Stakeholder(),
		functionsStakeholder.WStakeholder_Selection_Tipo_Stakeholder(),
		functionsStakeholder.WStakeholder_Lista_Tipo_Stakeholder(),

		functionsCadenaValor.WCadena_Valor_Agrega(),
		functionsCadenaValor.WCadena_Valor_Actualiza(),
		functionsCadenaValor.WCadena_Valor_Elimina(),
		functionsCadenaValor.WCadena_Valor_Lista(),
	
		functionsCadenaValor.WCadena_Valor_Agrega_Lote(),
		functionsCadenaValor.WCadena_Valor_Actualiza_Lote(),
		functionsCadenaValor.WCadena_Valor_Elimina_Lote(),
		functionsCadenaValor.WCadena_Valor_Selecciona_Lote(),

		functionsReporte.WReporte_Descargar_Excel_Proceso(),
		functionsReporte.WReporte_Lista_Etapa(),
		functionsReporte.WReporte_Lista_Proceso(),
		functionsReporte.WReporte_Cadena_Valor_List(),
		functionsReporte.WReporte_Proceso_Metadata_Etapas(),
		functionsReporte.WReporte_Detalle_Proceso(),

		functionsComercio.WComercio_Actualiza(),
		functionsComercio.WComercio_Agrega(),
		functionsComercio.WComercio_Elimina(),
		functionsComercio.WComercio_Lista(),
		functionsComercio.WComercio_Selecciona(),
		functionsComercio.WComercio_Lista_Cadena_Valor(),
		functionsComercio.WComercio_Lista_Lote(),
		functionsComercio.WComercio_Descarga_Excel()
	]);
}