import Type from "./constants"

//Cuenta
export const ACuenta_Login_Add = (Body: object) => ({type: Type.CUENTA_LOGIN_ADD, payload: Body})
export const ACuenta_Login_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CUENTA_LOGIN_CALL, payload: Body, resolve, reject}) 

export const ACuenta_Tablero_Control_Add = (Body: object) => ({type: Type.CUENTA_CONTROL_TABLERO_ADD, payload: Body})
export const ACuenta_Tablero_Control_Call = () => ({type: Type.CUENTA_CONTROL_TABLERO_CALL})

export const ACuenta_Cambia_Password_Add = (Body: object) => ({type: Type.CUENTA_CAMBIA_PASSWORD_ADD, payload: Body})
export const ACuenta_Cambia_Password_Call = (Body:object, resolve:any, reject:any) => ({type: Type.CUENTA_CAMBIA_PASSWORD_CALL, payload:Body, resolve, reject})

export const ACuenta_Menu_Lateral_Add = (Body: object) => ({type: Type.CUENTA_MENU_LATERAL_ADD, payload: Body})
export const ACuenta_Menu_Lateral_Call = () => ({type: Type.CUENTA_MENU_LATERAL_CALL})
//             Usuario
export const ACuenta_Agrega_Usuario_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CUENTA_AGREGA_USUARIO_CALL, payload:Body, resolve, reject});
export const ACuenta_Actualiza_Usuario_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CUENTA_ACTUALIZA_USUARIO_CALL, payload:Body, resolve, reject}); 
export const ACuenta_Elimina_Usuario_Call = (Id: number, resolve:any, reject:any) => ({type: Type.CUENTA_ELIMINA_USUARIO_CALL, payload:Id, resolve, reject}); 

export const ACuenta_Lista_Usuario_Add = (Body: object) => ({type: Type.CUENTA_LISTA_USUARIO_ADD, payload:Body}); 
export const ACuenta_Lista_Usuario_Call = (resolve:any, reject:any) => ({type: Type.CUENTA_LISTA_USUARIO_CALL, resolve, reject});

export const ACuenta_Selecciona_Usuario_Add = (Body: object) => ({type: Type.CUENTA_SELECCIONA_USUARIO_ADD, payload:Body}); 
export const ACuenta_Selecciona_Usuario_Call = (id:number, resolve:any, reject:any) => ({type: Type.CUENTA_SELECCIONA_USUARIO_CALL, payload:id, resolve, reject});
//              Usuario Stakeholder
export const ACuenta_Agrega_Usuario_Stakeholder_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CUENTA_AGREGA_USUARIO_STAKEHOLDER_CALL, payload:Body, resolve, reject});
export const ACuenta_Actualiza_Usuario_Stakeholder_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CUENTA_ACTUALIZA_USUARIO_STAKEHOLDER_CALL, payload:Body, resolve, reject}); 
export const ACuenta_Elimina_Usuario_Stakeholder_Call = (Id: number, resolve:any, reject:any) => ({type: Type.CUENTA_ELIMINA_USUARIO_STAKEHOLDER_CALL, payload:Id, resolve, reject}); 

export const ACuenta_Lista_Usuario_Stakeholder_Add = (Body: object) => ({type: Type.CUENTA_LISTA_USUARIO_STAKEHOLDER_ADD, payload:Body}); 
export const ACuenta_Lista_Usuario_Stakeholder_Call = (resolve:any, reject:any) => ({type: Type.CUENTA_LISTA_USUARIO_STAKEHOLDER_CALL, resolve, reject});

export const ACuenta_Selecciona_Usuario_Stakeholder_Add = (Body: object) => ({type: Type.CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_ADD, payload:Body}); 
export const ACuenta_Selecciona_Usuario_Stakeholder_Call = (id:number, resolve:any, reject:any) => ({type: Type.CUENTA_SELECCIONA_USUARIO_STAKEHOLDER_CALL, payload:id, resolve, reject});

export const ACuenta_Lista_Stakeholder_Add = (Body: object) => ({type: Type.CUENTA_LISTA_STAKEHOLDER_ADD, payload:Body}); 
export const ACuenta_Lista_Stakeholder_Call = (Body:object, resolve:any, reject:any) => ({type: Type.CUENTA_LISTA_STAKEHOLDER_CALL, payload:Body, resolve, reject});
//Proceso
export const AProceso_Agrega_Call = (Body: object, resolve:any, reject:any) => ({type: Type.PROCESO_AGREGA_CALL, payload: Body, resolve, reject});
export const AProceso_Actualiza_Call = (Body: object, resolve:any, reject:any) => ({type: Type.PROCESO_ACTUALIZA_CALL, payload: Body, resolve, reject});

export const AProceso_Selecciona_Call = (Id:number, resolve:any, reject:any) => ({type: Type.PROCESO_SELECCIONA_CALL, payload: Id, resolve, reject});
export const AProceso_Selecciona_Add = (Body:object) => ({type: Type.PROCESO_SELECCIONA_ADD, payload:Body});

export const AProceso_Lista_Proceso_Metadata_Add = (Body: object) => ({type: Type.PROCESO_LISTA_PROCESO_METADATA_ADD, payload:Body})
export const AProceso_Lista_Proceso_Metadata_Call = (resolve:any, reject:any, Function:any) => ({type: Type.PROCESO_LISTA_PROCESO_METADATA_CALL, resolve, reject, Function})

export const AProceso_Lista_Proceso_Etapa_Add = (Body:object) => ({type: Type.PROCESO_LISTA_PROCESO_ETAPA_ADD, payload:Body})
export const AProceso_Lista_Proceso_Etapa_Call = (Body:object, resolve:any, reject:any) => ({type: Type.PROCESO_LISTA_PROCESO_ETAPA_CALL, payload:Body, resolve, reject})

export const AProceso_Lista_Etapas_Parent_Add = (Body:object) => ({type: Type.PROCESO_LISTA_ETAPAS_PARENT_ADD, payload: Body})
export const AProceso_Lista_Etapas_Parent_Call = (Id:number, resolve:any, reject:any) => ({type: Type.PROCESO_LISTA_ETAPAS_PARENT_CALL, payload: Id, resolve, reject})

export const AProceso_Lista_Registros_Parent_Add = (Body:object) => ({type: Type.PROCESO_LISTA_REGISTROS_PARENT_ADD, payload: Body});
export const AProceso_Lista_Registros_Parent_Call = (Body:object, resolve:any, reject:any) => ({type: Type.PROCESO_LISTA_REGISTROS_PARENT_CALL, payload:Body, resolve, reject});

export const AProceso_Registros_Confeccion_Add = (Body:object) => ({type: Type.PROCESO_REGISTROS_CONFECCION_ADD, payload:Body})
export const AProceso_Registros_Confeccion_Call = (Body:object, resolve:any, reject:any) => ({type: Type.PROCESO_REGISTROS_CONFECCION_CALL, payload:Body, resolve, reject})

export const AProceso_Genera_Producto_Code_Add = (Body:object) => ({type: Type.PROCESO_GENERA_PRODUCTO_CODE_ADD, payload:Body});
export const AProceso_Genera_Producto_Code_Call = (Body:string, resolve:any, reject:any) => ({type: Type.PROCESO_GENERA_PRODUCTO_CODE_CALL, payload:Body, resolve, reject});

export const AProceso_Lista_Producto_Code_Add = (Body:object) => ({type: Type.PROCESO_LISTA_PRODUCTO_CODE_ADD, payload:Body});
export const AProceso_Lista_Producto_Code_Call = (Id:number, resolve:any, reject:any) => ({type: Type.PROCESO_LISTA_PRODUCTO_CODE_CALL, payload:Id, resolve, reject});

export const AProceso_Descarga_Qr_Add = (Body:object) => ({type: Type.PROCESO_DESCARGA_QR_ADD, payload: Body});
export const AProceso_Descarga_Qr_Call = (Body:object,resolve:any, reject:any) => ({type: Type.PROCESO_DESCARGA_QR_CALL, payload: Body, resolve, reject});

export const AProceso_Descarga_Pin_Add = (Body:object) => ({type: Type.PROCESO_DESCARGA_PIN_ADD, payload: Body});
export const AProceso_Descarga_Pin_Call = (Body:object,resolve:any, reject:any) => ({type: Type.PROCESO_DESCARGA_PIN_CALL, payload: Body, resolve, reject});

export const AProceso_Lista_Codesku_Add = (Body:any) => ({type: Type.PROCESO_LISTA_CODESKU_ADD, payload:Body});
export const AProceso_Lista_Codesku_Call = (resolve:any, reject:any) => ({type: Type.PROCESO_LISTA_CODESKU_CALL, resolve, reject});

export const AProceso_Actualizar_Trazabilidad_Blockchain = (resolve:any, reject:any) => ({type: Type.PROCESO_ACTUALIZAR_TRAZABILIDAD_BLOCKCHAIN, resolve, reject})

//StakeHolder
export const AStakeholder_Agrega_StakeHolder_Call = (Body: object, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_AGREGA_STAKEHOLDER_CALL, payload:Body, resolve, reject});
export const AStakeholder_Actualiza_Stakeholder_Call = (Body: object, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_ACTUALIZA_STAKEHOLDER_CALL, payload:Body, resolve, reject}); 
export const AStakeholder_Elimina_Stakeholder_Call = (Id: number, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_ELIMINA_STAKEHOLDER_CALL, payload:Id, resolve, reject}); 

export const AStakeholder_Lista_StakeHolder_Add = (Body: object) => ({type: Type.STAKEHOLDER_LISTA_STAKEHOLDER_ADD, payload:Body}); 
export const AStakeholder_Lista_StakeHolder_Call = (Body:object, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_LISTA_STAKEHOLDER_CALL, payload:Body, resolve, reject});

export const AStakeholder_Selecciona_StakeHolder_Add = (Body: object) => ({type: Type.STAKEHOLDER_SELECCIONA_STAKEHOLDER_ADD, payload:Body}); 
export const AStakeholder_Selecciona_StakeHolder_Call = (id:number, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_SELECCIONA_STAKEHOLDER_CALL, payload:id, resolve, reject});
//-----------------------------Tipo------------------------------//
export const AStakeholder_Agrega_Tipo_StakeHolder_Call = (Body: object, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_AGREGA_TIPO_STAKEHOLDER_CALL, payload:Body, resolve, reject});
export const AStakeholder_Actualiza_Tipo_Stakeholder_Call = (Body: object, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_ACTUALIZA_TIPO_STAKEHOLDER_CALL, payload:Body, resolve, reject}); 
export const AStakeholder_Elimina_Tipo_Stakeholder_Call = (Id: number, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_ELIMINA_TIPO_STAKEHOLDER_CALL, payload:Id, resolve, reject}); 
export const AStakeholder_Proceso_Tipo_Stakeholder_Call = (body: object) => ({type: Type.STAKEHOLDER_PROCESO_TIPO_STAKEHOLDER_ADD, payload:body});

export const AStakeholder_Lista_Tipo_StakeHolder_Add = (Body: object) => ({type: Type.STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_ADD, payload:Body}); 
export const AStakeholder_Lista_Tipo_StakeHolder_Call = (Body: object, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_CALL, payload:Body, resolve, reject});

export const AStakeholder_Selecciona_Tipo_StakeHolder_Add = (Body: object) => ({type: Type.STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_ADD, payload:Body}); 
export const AStakeholder_Selecciona_Tipo_StakeHolder_Call = (id:number, resolve:any, reject:any) => ({type: Type.STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_CALL, payload:id, resolve, reject});

//Cadena
//---------------------------Etapa-----------------------------//
export const ACadena_Valor_Lista_Etapa_Call = (Etapa:string, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_LISTA_ETAPA_CALL, payload:Etapa, resolve, reject});
export const ACadena_Valor_Lista_Etapa_Add = (Body:object) => ({type: Type.CADENA_VALOR_LISTA_ETAPA_ADD, payload:Body});
//---------------------------Cadena----------------------------//
export const ACadena_Valor_Agrega_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_AGREGA_CALL, payload:Body, resolve, reject});
export const ACadena_Valor_Actualiza_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_ACTUALIZA_CALL, payload:Body, resolve, reject}); 
export const ACadena_Valor_Elimina_Call = (data: any, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_ELIMINA_CALL, payload:data, resolve, reject}); 

export const ACadena_Valor_Lista_Add = (Body:object) => ({type: Type.CADENA_VALOR_LISTA_ADD, payload:Body});
export const ACadena_Valor_Lista_Call = (Body:object, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_LISTA_CALL,payload:Body, resolve, reject});
//---------------------------Lote------------------------------//
export const ACadena_Valor_Agrega_Lote_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_AGREGA_LOTE_CALL, payload:Body, resolve, reject});
export const ACadena_Valor_Actualiza_Lote_Call = (Body: object, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_ACTUALIZA_LOTE_CALL, payload:Body, resolve, reject}); 
export const ACadena_Valor_Elimina_Lote_Call = (Id: number, resolve:any, reject:any) => ({type: Type.CADENA_VALOR_ELIMINA_LOTE_CALL, payload:Id, resolve, reject});

export const ACadena_Valor_Selecciona_Lote_Add = (Body:object) => ({type: Type.CADENA_VALOR_SELECCIONA_LOTE_ADD, payload:Body});
export const ACadena_Valor_Selecciona_Lote_Call = (resolve:any, reject:any) => ({type: Type.CADENA_VALOR_SELECCIONA_LOTE_CALL, resolve, reject});
//Reporte
export const AReporte_Lista_Etapa_Call = (Body: object, resolve:any, reject:any) => ({type: Type.REPORTE_LISTA_ETAPA_CALL, payload:Body, resolve, reject });
export const AReporte_Lista_Etapa_Add = (Body:object) => ({type: Type.REPORTE_LISTA_ETAPA_ADD, payload:Body });

export const AReporte_Lista_Procesos_Call = (Body:object, resolve:any, reject:any) => ({type: Type.REPORTE_LISTA_PROCESO_CALL, payload:Body, resolve, reject });
export const AReporte_Lista_Proceos_Add = (Body: object) => ({type: Type.REPORTE_LISTA_PROCESO_ADD, payload:Body });

export const AReporte_Descarga_Excel_Proceso_Add = (Body:object) => ({type: Type.REPORTE_DESCARGA_EXCEL_PROCESO_ADD, payload:Body});
export const AReporte_Descarga_Excel_Proceso_Call = (Body:object, resolve:any, reject:any) => ({type: Type.REPORTE_DESCARGA_EXCEL_PROCESO_CALL, payload:Body, resolve, reject });

export const AReporte_Cadena_Valor_Lista_Call = (Body:object, resolve:any, reject:any) => ({ type: Type.REPORTE_CADENA_VALOR_LISTA_CALL, payload:Body, resolve, reject });
export const AReporte_Cadena_Valor_Lista_Add = (Body:object) => ({ type: Type.REPORTE_CADENA_VALOR_LISTA_ADD, payload:Body });

export const AReporte_Proceso_Metadata_Etapas_Call = (resolve:any, reject:any) => ({ type: Type.REPORTE_PROCESO_METADATA_ETAPAS_CALL, resolve, reject});
export const AReporte_Proceso_Metadata_Etapas_Add = (Body:object) => ({ type: Type.REPORTE_PROCESO_METADATA_ETAPAS_ADD, payload:Body});

export const AReporte_Detalle_Proceso_Call = (Id:number, resolve:any, reject:any) => ({ type: Type.REPORTE_DETALLE_PROCESO_CALL, payload:Id, resolve, reject })
export const AReporte_Detalle_Proceso_Add = (Body:object) => ({ type: Type.REPORTE_DETALLE_PROCESO_ADD, payload:Body })

//Comercio
export const AComercio_Agrega_Call = (Body: object, resolve:any, reject:any) => ({type: Type.COMERCIO_AGREGA_CALL, payload:Body, resolve, reject});
export const AComercio_Actualiza_Call = (Body: object, resolve:any, reject:any) => ({type: Type.COMERCIO_ACTUALIZA_CALL, payload:Body, resolve, reject}); 
export const AComercio_Elimina_Call = (Id: number, resolve:any, reject:any) => ({type: Type.COMERCIO_ELIMINA_CALL, payload:Id, resolve, reject});

export const AComercio_Selecciona_Add = (Body:object) => ({type: Type.COMERCIO_SELECCIONA_ADD, payload:Body});
export const AComercio_Selecciona_Call = (Id:number, resolve:any, reject:any) => ({type: Type.COMERCIO_SELECCIONA_CALL, payload:Id, resolve, reject});

export const AComercio_Lista_Add = (Body:object) => ({type: Type.COMERCIO_LISTA_ADD, payload:Body});
export const AComercio_Lista_Call = (Body:any, resolve:any, reject:any) => ({type: Type.COMERCIO_LISTA_CALL, payload:Body, resolve, reject});

export const AComercio_Descarga_Excel_Add = (Body:object) => ({type: Type.COMERCIO_DESCARGA_EXCEL_ADD, payload:Body});
export const AComercio_Descarga_Excel_Call = (Body:any, resolve:any, reject:any) => ({type: Type.COMERCIO_DESCARGA_EXCEL_CALL, payload:Body, resolve, reject});
/*------------------------Cadena Valor------------------------- */
export const AComercio_Lista_Cadena_Valor_Add = (Body:object) => ({type: Type.COMERCIO_LISTA_CADENA_VALOR_ADD, payload:Body});
export const AComercio_Lista_Cadena_Valor_Call = (Grupo:string, resolve:any, reject:any) => ({type: Type.COMERCIO_LISTA_CADENA_VALOR_CALL, payload:Grupo, resolve, reject});
/*----------------------Lote------------------------------------- */
export const AComercio_Lista_Lote_Add = (Body:object) => ({type: Type.COMERCIO_LISTA_LOTE_ADD, payload:Body});
export const AComercio_Lista_Lote_Call = (Id:number, resolve:any, reject:any) => ({type: Type.COMERCIO_LISTA_LOTE_CALL, payload:Id, resolve, reject});














/**
 * INTERNAL PAGE
 */
export const AInternalPage_Call_List = (Body: object) => {
    return { type: Type.INTERNAL_PAGE_CALL_LIST, payload: Body }
}

export const AInternalPage_Add_List = (Body: JSON) => {
    return { type: Type.INTERNAL_PAGE_ADD_LIST, payload: Body }
}

export const AInternalPage_Call_Get = (Id: string) => {
    return { type: Type.INTERNAL_PAGE_CALL_GET, payload: Id }
}

export const AInternalPage_Add_Get = (Body: JSON) => {
    return { type: Type.INTERNAL_PAGE_ADD_GET, payload: Body }
}

/**
 * NEWS
 */
export const ANews_Call_Categories = () => {
    return { type: Type.NEWS_CALL_CATEGORIES}
}

export const ANews_Add_Categories = (Body: JSON) => {
    return { type: Type.NEWS_ADD_CATEGORIES, payload: Body }
} 

export const ANews_Call_Interesting = () => {
    return { type: Type.NEWS_CALL_INTERESTING}
}

export const ANews_Add_Interesting = (Body: JSON) => {
    return { type: Type.NEWS_ADD_INTERESTING, payload: Body }
} 

export const ANews_Call_List = (Body: object, resolve: Function, reject: Function) => {
    return { type: Type.NEWS_CALL_LIST, payload: Body, resolve, reject }
}

export const ANews_Add_List = (Body: JSON) => {
    return { type: Type.NEWS_ADD_LIST, payload: Body }
} 

export const ANews_Call_Get = (Id: string) => {
    return { type: Type.NEWS_CALL_GET, payload: Id }
}

export const ANews_Add_Get = (Body: JSON) => {
    return { type: Type.NEWS_ADD_GET, payload: Body }
}

/**
 * GALLERY
 */

export const AGallery_Call_List = () => {
	return { type: Type.GALLERY_CALL_LIST}
}

export const AGallery_Add_List = (Body: JSON) => {
    return { type: Type.GALLERY_ADD_LIST, payload: Body }
}

export const AGallery_Call_List_Content = (Body: JSON) => {
    return { type: Type.GALLERY_CALL_LIST_CONTENT, payload: Body }
} 

export const AGallery_Add_List_Content = (Body: JSON) => {
    return { type: Type.GALLERY_ADD_LIST_CONTENT, payload: Body }
} 

/**
 * PAGE ZONE
 */
export const APageZone_Call_List = (Id: string) => {
    return { type: Type.PAGE_ZONE_CALL_LIST, payload: Id }
}

export const APageZone_Add_List = (Body: JSON) => {
    return { type: Type.PAGE_ZONE_ADD_LIST, payload: Body }
}

export const APageZone_Call_Get = (Id: number) => {
    return { type: Type.PAGE_ZONE_CALL_GET, payload: Id }
}

export const APageZone_Add_Get = (Body: JSON) => {
    return { type: Type.PAGE_ZONE_ADD_GET, payload: Body }
}

/**
 * BANNERS
 */
 export const ABanner_Call_List = (Id: string) => {
    return { type: Type.BANNER_CALL_LIST, payload: Id }
}

export const ABanner_Add_List = (Body: JSON) => {
    return { type: Type.BANNER_ADD_LIST, payload: Body }
}

export const ABanner_Call_Get = (Id: string) => {
    return { type: Type.BANNER_CALL_GET, payload: Id }
}

export const ABanner_Add_Get = (Body: JSON) => {
    return { type: Type.BANNER_ADD_GET, payload: Body }
}

/**
 * EVENTS
 */
export const AEvents_Call_Categories = () => {
    return { type: Type.EVENTS_CALL_CATEGORIES }
}

export const AEvents_Add_Categories = (Body: JSON) => {
    return { type: Type.EVENTS_ADD_CATEGORIES, payload: Body }
} 

export const AEvents_Call_Upcoming = () => {
    return { type: Type.EVENTS_CALL_UPCOMING }
}

export const AEvents_Add_Upcoming = (Body: JSON) => {
    return { type: Type.EVENTS_ADD_UPCOMING, payload: Body }
} 

export const AEvents_Call_List = (Id: string) => {
    return { type: Type.EVENTS_CALL_LIST, payload: Id }
}

export const AEvents_Add_List = (Body: JSON) => {
    return { type: Type.EVENTS_ADD_LIST, payload: Body }
} 

export const AEvents_Call_Get = (Id: string) => {
    return { type: Type.EVENTS_CALL_GET, payload: Id }
}

export const AEvents_Add_Get = (Body: JSON) => {
    return { type: Type.EVENTS_ADD_GET, payload: Body }
}

/**
 * FORMS
 */
export const AForm_Call_Get = (Id: string) => {
    return { type: Type.FORM_CALL_GET, payload: Id }
}

export const AForm_Add_Get = (Body: JSON) => {
    return { type: Type.FORM_ADD_GET, payload: Body }
}

export const AForm_Call_Send = (Id: string) => {
    return { type: Type.FORM_CALL_SEND, payload: Id }
}

export const AForm_Add_Send = (Body: JSON) => {
    return { type: Type.FORM_ADD_SEND, payload: Body }
}

/**
 * SEARCHER
 */
 export const ASearcher_Call_List = (Body: object, resolve: Function, reject: Function) => {
    return { type: Type.SEARCHER_CALL_LIST, payload: Body, resolve, reject }
}

export const ASearcher_Add_List = (Body: JSON) => {
    return { type: Type.SEARCHER_ADD_LIST, payload: Body }
}