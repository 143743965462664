import ActionType from './../../constants';

const initialState = {
    onlyOne:[],
    List:[]
}

const RComercio = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.COMERCIO_SELECCIONA_ADD : {
            return { 
                ...state,
                onlyOne: action.payload
            }
        }
        case ActionType.COMERCIO_LISTA_ADD : {
            return {
                ...state,
                List: action.payload
            }
        }
        case ActionType.COMERCIO_DESCARGA_EXCEL_ADD : {
            return {
                ...state,
                Excel: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RComercio;