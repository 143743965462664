import { combineReducers } from 'redux';
import cadena_valor from './Cadena_Valor';
import comercio from './Comercio';
import lote from './Lote';

const RIndex = combineReducers({
    cadena_valor,
    comercio,
    lote
})

export default RIndex;