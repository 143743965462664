import { takeLatest, call, put } from "redux-saga/effects";
import API_SERVICE from "../../services/api";
import ActionType from "../constants";
import * as Action from "../actions";

function* EStakeholder_agrega_Stakeholder(action:any):any{
    try{
        const res = (yield call(API_SERVICE.Stakeholder_agrega_stakeholder, action.payload));

        const List = (yield call (API_SERVICE.Stakeholder_lista_stakeholder, {})).data.result;
        orderById(List);
        yield put(Action.AStakeholder_Lista_StakeHolder_Add(List));

        action.resolve(res.data.message);
    }
    catch(err){
        try {
            action.reject(err.response.data.responseException.exceptionMessage);
        }
        catch(err){
            action.reject(err.response.data.responseException);   
        }
    }
}

function* EStakeholder_Elimina_Stakeholder(action:any):any{
    try{
        const res = yield call(API_SERVICE.Stakeholder_elimina_stakeholder, action.payload);

        
        const List = (yield call (API_SERVICE.Stakeholder_lista_stakeholder, {})).data.result;   
        orderById(List);
        yield put(Action.AStakeholder_Lista_StakeHolder_Add(List));

        action.resolve(res.data.message);
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
} 

function* EStakeholder_actualiza_Stakeholder(action:any):any{
    try{
        const res = yield call(API_SERVICE.Stakeholder_actualiza_stakeholder, action.payload)
        
        const List = (yield call (API_SERVICE.Stakeholder_lista_stakeholder, {})).data.result;
        orderById(List);
        yield put(Action.AStakeholder_Lista_StakeHolder_Add(List));

        action.resolve(res.data.message);
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* EStakeholder_Lista_Stakeholder(action:any):any{

    try{
        const List = (yield call (API_SERVICE.Stakeholder_lista_stakeholder, action.payload || {})).data.result;

        if(!action.payload){
        const List_Type = (yield call (API_SERVICE.Stakeholder_lista_tipo_stakeholder, {})).data.result;
        yield put(Action.AStakeholder_Lista_Tipo_StakeHolder_Add(List_Type));
        }
        
        orderById(List);

        yield put(Action.AStakeholder_Lista_StakeHolder_Add(List));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EStakeholder_Selection_Stakeholder(action:any):any{
    try{
        const OnlyOne = (yield call (API_SERVICE.Stakeholder_selecciona_Stakeholder, action.payload)).data.result;
        yield put(Action.AStakeholder_Selecciona_StakeHolder_Add(OnlyOne));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}


function* EStakeholder_Agrega_Tipo_Stakeholder(action:any):any{
    try{
        const res = yield call(API_SERVICE.Stakeholder_agrega_tipo_stakeholder, action.payload);
        const List = (yield call (API_SERVICE.Stakeholder_lista_tipo_stakeholder, {})).data.result;
        
        orderById(List);

        List.map((item:any) => {
            item.estado === "A" ? item.estado = "Activo" : item.estado = "Inactivo";
        })
        

        yield put(Action.AStakeholder_Lista_Tipo_StakeHolder_Add(List));

        action.resolve(res.data.message);
    }
    catch(err){
        try {
            action.reject(err.response.data.responseException.exceptionMessage);
        }
        catch(err){
            action.reject(err.response.data.responseException);   
        }
    }
}

function* EStakeholder_Elimina_Tipo_Stakeholder(action:any):any{
    try{
        const res = yield call(API_SERVICE.Stakeholder_elimina_tipo_stakeholder, action.payload);
        const List = (yield call (API_SERVICE.Stakeholder_lista_tipo_stakeholder, {})).data.result;
        
        orderById(List);
        
        List.map((item:any) => {
            item.estado === "A" ? item.estado = "Activo" : item.estado = "Inactivo";
        })

        yield put(Action.AStakeholder_Lista_Tipo_StakeHolder_Add(List));

        action.resolve(res.data.message);
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
} 

function* EStakeholder_Actualiza_Tipo_Stakeholder(action:any):any{
    try{
        const res = yield call(API_SERVICE.Stakeholder_actualiza_tipo_stakeholder, action.payload)
        const List = (yield call (API_SERVICE.Stakeholder_lista_tipo_stakeholder, {})).data.result;
        
        orderById(List);

        List.map((item:any) => {
            item.estado === "A" ? item.estado = "Activo" : item.estado = "Inactivo";
        })

        yield put(Action.AStakeholder_Lista_Tipo_StakeHolder_Add(List));

        action.resolve(res.data.message);
    }
    catch(err){
        action.reject(err.response.data.responseException.exceptionMessage);
    }
}

function* EStakeholder_Lista_Tipo_Stakeholder(action:any):any{
    try{
        const List = (yield call (API_SERVICE.Stakeholder_lista_tipo_stakeholder, action.payload.search)).data.result;

        orderById(List);

        List.map((item:any) => 
            item.estado === "A" ? item.estado = "Activo" : item.estado = "Inactivo"
        )

        if(action.payload.TraerListaProceso){
            const Proceso = (yield call (API_SERVICE.Cadena_Valor_Lista_Etapa)).data.result;
            const FormatProceso:Array<object> = [];
            Proceso.map((item:any) => FormatProceso.push({label: item.Nombre, value: item.Id}));
            yield put(Action.AStakeholder_Proceso_Tipo_Stakeholder_Call(FormatProceso));
        };

        yield put(Action.AStakeholder_Lista_Tipo_StakeHolder_Add(List));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

function* EStakeholder_Selection_Tipo_Stakeholder(action:any):any{
    try {
        const OnlyOne = (yield call (API_SERVICE.Stakeholder_selecciona_tipo_Stakeholder, action.payload)).data.result;

        let data = OnlyOne.data;
        data.permisos = [];
        
        OnlyOne.permisos.map((item:any) => {
            if(item.Asignado) data.permisos.push(item.Id);
        })

        yield put(Action.AStakeholder_Selecciona_Tipo_StakeHolder_Add(data));
        action.resolve();
    }
    catch(err){
        action.reject();
    }
}

export function* WStakeholder_Agrega_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_AGREGA_STAKEHOLDER_CALL, EStakeholder_agrega_Stakeholder)};
export function* WStakeholder_Elimina_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_ELIMINA_STAKEHOLDER_CALL, EStakeholder_Elimina_Stakeholder)};
export function* WStakeholder_Actualiza_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_ACTUALIZA_STAKEHOLDER_CALL, EStakeholder_actualiza_Stakeholder)};
export function* WStakeholder_Lista_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_LISTA_STAKEHOLDER_CALL, EStakeholder_Lista_Stakeholder)};
export function* WStakeholder_Selection_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_SELECCIONA_STAKEHOLDER_CALL, EStakeholder_Selection_Stakeholder)};

export function* WStakeholder_Agrega_Tipo_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_AGREGA_TIPO_STAKEHOLDER_CALL, EStakeholder_Agrega_Tipo_Stakeholder)};
export function* WStakeholder_Elimina_Tipo_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_ELIMINA_TIPO_STAKEHOLDER_CALL, EStakeholder_Elimina_Tipo_Stakeholder)};
export function* WStakeholder_Actualiza_Tipo_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_ACTUALIZA_TIPO_STAKEHOLDER_CALL, EStakeholder_Actualiza_Tipo_Stakeholder)};
export function* WStakeholder_Lista_Tipo_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_LISTA_TIPO_STAKEHOLDER_CALL, EStakeholder_Lista_Tipo_Stakeholder)};
export function* WStakeholder_Selection_Tipo_Stakeholder():any{ return yield takeLatest(ActionType.STAKEHOLDER_SELECCIONA_TIPO_STAKEHOLDER_CALL, EStakeholder_Selection_Tipo_Stakeholder)};

const orderById = (Array:any) => {
    const newArray = Array.sort((a:any, b:any) => b.id - a.id);
    return newArray; 
}