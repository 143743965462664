import ActionType from './../../constants';

const initialState = {
    onlyOne:[],
    Lista:[]
}

const RStakeholder = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.STAKEHOLDER_SELECCIONA_STAKEHOLDER_ADD : {
            return { 
                ...state,
                onlyOne: action.payload
            }
        }
        case ActionType.STAKEHOLDER_LISTA_STAKEHOLDER_ADD : {
            return {
                ...state,
                Lista: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RStakeholder;