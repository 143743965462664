import ActionType from '../../constants';

const initialState = {
    user:[],
    control:[],
    menu:[]
}

const RCuenta = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.CUENTA_LOGIN_ADD : {
            return { 
                ...state,
                user: action.payload
            }
        }
        case ActionType.CUENTA_CONTROL_TABLERO_ADD : {
            return {
                ...state,
                control: action.payload
            }
        }
        case ActionType.CUENTA_MENU_LATERAL_ADD : {
            return {
                ...state,
                menu: action.payload
            }
        }
        case ActionType.CUENTA_CAMBIA_PASSWORD_ADD: {
            return {
                ...state,
                cambPassword: action.payload
            }
        }
        default : 
            return state;
    }
}

export default RCuenta