import ActionType from './../../constants';

const initialState = {
    List:[]
}

const RLote = (state = initialState, action:any ) => {
    switch(action.type){
        case ActionType.COMERCIO_LISTA_LOTE_ADD: {
            return { 
                ...state,
                List: action.payload
            }
        }
        default:
            return state;
    }
}

export default RLote;