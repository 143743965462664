import { combineReducers } from 'redux';
import cadenaValor from './cadenaValor';
import cadenaValor_Lote from './cadenaValorLote';
import cadenaValor_Etapa from './cadenaValorEtapa';

const RIndex = combineReducers({
    cadenaValor,
    cadenaValor_Lote,
    cadenaValor_Etapa
})

export default RIndex;